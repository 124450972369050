var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',{staticClass:"justify-space-between mb-0 py-7"},[_c('span',{staticClass:"text-h5"},[_c('strong',[_vm._v(" Tissue Detail")])])]),_c('v-row',{staticClass:"ml-2 mr-2 mb-3"},[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-text-field',{attrs:{"label":"Tissue ID","outlined":"","dense":"","hide-details":""},model:{value:(_vm.filter.tissue_id),callback:function ($$v) {_vm.$set(_vm.filter, "tissue_id", $$v)},expression:"filter.tissue_id"}})],1),_c('v-col',{staticClass:"pl-0",attrs:{"cols":"12","md":"3"}},[_c('v-text-field',{attrs:{"label":"Primary COD","outlined":"","dense":"","hide-details":""},model:{value:(_vm.filter.primary_cod),callback:function ($$v) {_vm.$set(_vm.filter, "primary_cod", $$v)},expression:"filter.primary_cod"}})],1),_c('v-col',{staticClass:"pl-0",attrs:{"cols":"12","md":"3"}},[_c('v-text-field',{attrs:{"label":"Approved Usages","outlined":"","dense":"","hide-details":""},model:{value:(_vm.filter.approved_usages),callback:function ($$v) {_vm.$set(_vm.filter, "approved_usages", $$v)},expression:"filter.approved_usages"}})],1),_c('v-col',{staticClass:"pl-0 text-right",attrs:{"cols":"12","md":"3"}},[_c('v-btn',{staticClass:"mr-2",attrs:{"color":"primary"},on:{"click":function($event){return _vm.AllSearch()}}},[_vm._v(" Search")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.AllView()}}},[_vm._v("Reset")])],1)],1),_c('v-data-table',{staticClass:"table-rounded",attrs:{"headers":_vm.headers,"items":_vm.RecipientDetail,"items-per-page":10,"fixed-header":"","height":"400","item-key":"donor_id","single-expand":_vm.singleExpand,"expanded":_vm.expanded,"show-expand":""},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
var item = ref.item;
return [(item.pdf_path != '')?_c('v-btn',{attrs:{"color":"primary","href":("https://api.stage.ebsr.in/" + (item.pdf_path)),"target":"_blank"}},[_vm._v(" Print ")]):_vm._e()]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-row',{staticClass:"pt-3 col-12"},[_c('v-col',{attrs:{"sm":"6","md":"6"}},[_c('p',[_c('strong',[_vm._v(" Donor Information: ")])]),_c('p',[_vm._v("Donor Name: "+_vm._s(item.donor_name))]),_c('p',[_vm._v("Donor Age: "+_vm._s(item.age))]),_c('p',[_vm._v("Donor Gender: "+_vm._s(item.gender))]),_c('p',[_vm._v("Date-Time of Death: "+_vm._s(_vm.moment.utc(item.date_time_of_death).format("DD-MM-YYYY HH:mm")))]),_c('p',[_vm._v("Date-Time of in situ: "+_vm._s(_vm.moment.utc(item.date_time_of_in_situ).format("DD-MM-YYYY HH:mm")))])]),_c('v-col',{attrs:{"sm":"6","md":"6"}},[_c('p',[_c('strong',[_vm._v("Serology")])]),_c('p',[_vm._v("HBsAG: "+_vm._s(item.HBsAG))]),_c('p',[_vm._v("HCV: "+_vm._s(item.HCV))]),_c('p',[_vm._v("HIV I/II: "+_vm._s(item.HIV_I_II))]),_c('p',[_vm._v("Spyhills: "+_vm._s(item.spyhills))]),_c('p',[_vm._v("Final Release By: "+_vm._s(item.final_release_by))]),_c('p',[_vm._v("Final Release Date-Time: "+_vm._s(_vm.moment.utc(item.final_release_date_time).format("DD-MM-YYYY HH:mm")))])])],1)],1)]}}],null,true)}),_c('v-snackbar',{attrs:{"timeout":_vm.timeout},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"blue","text":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.snackbarText)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }